<div id="page" class="page">
    <header id="header" class="header white-menu navbar-dark">
        <div class="header-wrapper">

            <div class="wsmobileheader clearfix">
                <span class="smllogo"><img src="../assets/../assets/images/logo_nail.png" alt="mobile-logo"></span>
                <a id="wsnavtoggle" class="wsanimated-arrow"><span></span></a>
                <a href="tel:123456789" class="callusbtn ico-20"><span class="flaticon-phone-call-1"></span></a>
            </div>

          
            <div class="wsmainfull menu clearfix centered-menu">
                <div class="wsmainwp clearfix">


                    <nav class="wsmenu clearfix">
                        <ul class="wsmenu-list">


                            <li aria-haspopup="true"><a href="#">About <span class="wsarrow"></span></a>
                                <ul class="sub-menu">
                                    <li aria-haspopup="true"><a href="#footer-3">About Studio</a></li>
                                    <!-- <li aria-haspopup="true"><a href="javascript:;">Why Pokeme?</a></li>
                                    <li aria-haspopup="true"><a href="javascript:;">Testimonials</a></li>
                                    <li aria-haspopup="true"><a href="javascript:;">Best Experience</a></li>
                                    <li aria-haspopup="true"><a href="javascript:;">Our Promotions</a></li> -->
                                </ul>
                            </li>


                            <!-- DROPDOWN MENU -->
                            <li aria-haspopup="true"><a href="#">Pages <span class="wsarrow"></span></a>
                                <div class="wsmegamenu clearfix halfmenu">
                                    <div class="container-fluid">
                                        <div class="row">

                                            <!-- Links -->
                                            <ul class="col-lg-6 link-list">
                                                <li><a href="javascript:;">About Us</a></li>
                                                <!-- <li><a href="javascript:;">Services & Prices</a></li> -->
                                                <li><a href="#gallery-4">Gallery Page</a></li>
                                                <!-- <li><a href="javascript:;">Gift Cards</a></li>
                                                <li><a href="javascript:;">Artists + Staff</a></li>
                                                <li><a href="javascript:;">Careers</a></li> -->
                                            </ul>

                                            <!-- Links -->
                                            <!-- <ul class="col-lg-6 link-list">
                                                <li><a href="javascript:;">FAQs Page</a></li>
                                                <li><a href="javascript:;">Blog Listing</a></li>
                                                <li><a href="javascript:;">Single Blog Post</a></li>
                                                <li><a href="javascript:;">Booking Page</a></li>
                                                <li><a href="javascript:;">Contact Us</a></li>
                                                <li><a href="javascript:;">Our Locations</a></li>
                                            </ul> -->

                                        </div>
                                    </div>
                                </div>
                            </li> <!-- END DROPDOWN MENU -->


                            <!-- SIMPLE NAVIGATION LINK -->
                            <li class="nl-simple" aria-haspopup="true"><a href="#gallery-4">Our Services</a></li>


                            <!-- HEADER LOGO -->
                            <li aria-haspopup="true" class="wscenterlogo">
                                <a href="#hero-1" class="logo-black"><img src="../assets/images/logo_nail.png"
                                        alt="header-logo"></a>
                                <a href="#hero-1" class="logo-white"><img src="../assets/images/logo-white.png"
                                        alt="header-logo"></a>
                            </li>


                            <!-- SIMPLE NAVIGATION LINK -->
                            <li class="nl-simple" aria-haspopup="true"><a href="#gallery-4">Gallery</a></li>


                            <!-- SIMPLE NAVIGATION LINK -->
                            <li class="nl-simple" aria-haspopup="true"><a href="booking.html">Booking</a></li>


                            <!-- HEADER BUTTON -->
                            <li class="nl-simple" aria-haspopup="true">
                                <a href="#footer-3" class="btn rose--btn tra-black--hover last-link">Contacts</a>
                            </li>


                        </ul>
                    </nav> <!-- END MAIN MENU -->

                </div>
            </div> <!-- END NAVIGATION MENU -->


        </div> <!-- End header-wrapper -->
    </header>




    
    <router-outlet></router-outlet>


			<!-- ABOUT-5
			============================================= -->
			<section id="about-5" class="wide-100 about-section division">
				<div class="container">
					<div class="row d-flex align-items-center">


						<!-- TEXT TABLE -->	
						<div class="col-md-6 order-last order-md-2">
							<div class="txt-table left-column wow fadeInRight">

								<!-- Table -->
								<table class="table">
									<tbody>
									    <tr>
									      	<td>Mon – Wed</td>
									      	<td> - </td>
									      	<td class="text-end">8:30 AM - 9:00 PM</td>
									    </tr>
									    <tr>
									      	<td>Thursday</td>
									      	<td> - </td>
									      	<td class="text-end">8:30 AM - 9:00 PM</td>
									    </tr>
									     <tr>
									      	<td>Friday</td>
									      	<td> - </td>
									      	<td class="text-end">8:30 AM - 9:00 PM</td>
									    </tr>
									    <tr class="last-tr">
									      	<td>Sun - Sun</td>
									      	<td>-</td>
									      	<td class="text-end">8:30 AM - 5:00 PM</td>
									   	 </tr>
									  </tbody>
								</table>
							</div>
						</div>	<!-- END TEXT TABLE -->	


						<!-- TEXT BLOCK -->	
						<div class="col-md-6 order-first order-md-2">
							<div class="txt-block right-column wow fadeInLeft">

								<!-- Section ID -->	
						 		<span class="section-id">Time Schedule</span>

								<!-- Title -->
								<h3 class="h3-xs">Working Hours</h3>

								<!-- Text -->
								<p class="p-lg">With a focus on high standards and elegance, we offer effective solutions and exceptional care. 
									Our services are designed to ensure precision, comfort, and style, addressing all your needs with excellence and attention to detail.
								</p> 
									
							</div>	
						</div>	<!-- END TEXT BLOCK -->	


					</div>	  <!-- End row -->
				</div>	   <!-- End container -->	
			</section>	<!-- END ABOUT-5 -->

    <footer id="footer-3" class="1bg--deep-blue footer division" style="background-color: #363636; color: white;">
        <div class="container 1white-smoke--color">
    
            <div class="row">
    
                <div class="col-sm-6 col-lg-3">
                    <div class="footer-contacts mb-30">
                        <h5 class="h5-md" style="color: #ffffff;">Location</h5> <!-- Màu sáng -->
                        <p class="p-lg txt-600">Pokeme Studio</p>
                        <p class="p-lg">252B Union street, Aberdeen, AB10 1TN</p>
                    </div>
                </div>
    
                <div class="col-sm-6 col-lg-3">
                    <div class="footer-contacts mb-30">
                        <h5 class="h5-md" style="color: #ffffff;">Contacts</h5> <!-- Màu sáng -->
                        <p class="p-lg"><a href="tel:123456789" style="color: white;">123-456-789</a></p>
                        <p class="p-lg"><a href="mailto:yourdomain@mail.com" class="txt-600" style="color: white;">Pokeme Beauty</a></p>
                        <ul class="foo-socials ico-20 text-center clearfix">											
                            <li><a href="#" class="ico-facebook" style="color: white;"><span class="flaticon-instagram"></span></a></li>
                            <li><a href="#" class="ico-twitter" style="color: white;"><span class="flaticon-facebook"></span></a></li>
                            <li><a href="#" class="ico-youtube" style="color: white;"><span class="flaticon-yelp"></span></a></li>
                            <li><a href="#" class="ico-youtube" style="color: white;"><span class="flaticon-vimeo"></span></a></li>
                        </ul>
                    </div>
                </div>
    
                <div class="col-sm-6 col-lg-3">
                    <div class="footer-info mb-30">
                        <h5 class="h5-md" style="color: #ffffff;">Opening Hours</h5> <!-- Màu sáng -->
                        <p class="p-lg">Mon-Fri: 10:00AM - 9:00PM</p>
                        <p class="p-lg">Saturday: 10:00AM - 7:00PM</p>
                        <p class="p-lg">Sunday: 10:00PM - 7:00PM</p>
                    </div>
                </div>
    
                <div class="col-sm-6 col-lg-3">
                    <div class="google-map mb-30">	
                        <a href="https://www.google.com/maps/place/252+Union+St,+Aberdeen+AB10+1TN/@57.1442268,-2.1103322,17z/data=!3m1!4b1!4m6!3m5!1s0x48840e241637ce6f:0x66902f473d32ee0b!8m2!3d57.1442268!4d-2.1077573!16s%2Fg%2F11c244xk5n?entry=ttu&g_ep=EgoyMDI0MDkyNS4wIKXMDSoASAFQAw%3D%3D" target="_blank">
                            <img class="img-fluid" src="../assets/images/map.png" alt="footer-map">
                        </a>								
                    </div>
                </div>
    
            </div>
    
            <div class="bottom-footer">
                <div class="row row-cols-1 row-cols-md-2 d-flex align-items-center">
                    <div class="col">
                        <div class="footer-copyright"><p class="p-md">&copy; 2024 POKEME. All Rights Reserved</p></div>
                    </div>
    
                    <div class="col">
                        <ul class="bottom-footer-list text-end">
                            <li class="first-li"><p class="p-md"><a href="#" style="color: white;">Privacy Policy</a></p></li>
                            <li class="last-li"><p class="p-md"><a href="#" style="color: white;">Terms & Conditions</a></p></li>
                        </ul>
                    </div>
                </div>
            </div>
    
        </div>
    </footer>


</div> <!-- END PAGE CONTENT -->